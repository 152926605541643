
import { modalController, popoverController, menuController } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import TargetNamePopover from "@/views/OKR/selectPullDown/TargetNamePopover.vue";
import SearchConditionPopover from "@/views/OKR/selectPullDown/SearchConditionPopover.vue";
import SelectOrganization from "@/views/OKR/modals/SelectOrganization.vue";
import Utils from "@/utils/Utils";
import Setting from "@/rest/Setting";
import OKRSearchPanel from "./OKRSearchPanel.vue";
import { ObjectiveService } from "@/services/okr/ObjectiveService";
import { SearchPanelService } from "@/services/searchPanel/SearchPanelService";
import { LeaveService } from "@/services/okr/LeaveService";
import { UserRelationService } from "@/services/mine/UserRelationService";
import SystemUtils from "@/utils/SystemUtils";
import User from "@/models/task/User";
import TargetCards from "@/components/ListCard/TargetCards.vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import moment from "moment";
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import KeyResultItem from "@/components/ListCard/KeyResultItem.vue";
import { KeyResultService } from "@/services/okr/KeyResultService";
import OkrOrgTree from "@/views/OKR/OkrOrgTree.vue";
export default defineComponent({
  name: "OKRPage",
  components: {
    TargetNamePopover,
    SearchConditionPopover,
    SelectOrganization,
    OKRSearchPanel,
    TargetCards,
    CardLoading,
    KeyResultItem,
    OkrOrgTree,
  },
  watch: {
    $route(from, to) {
      if (to.path == "/OKR/details") this.resetPage();
    },
  },
  data() {
    const router = useRouter();
    const _param: any = {};
    const searchPanelService = new SearchPanelService();
    const user: User = {
      id: "",
      pernr: "",
    };
    return {
      relationService: new UserRelationService(),
      objectiveService: new ObjectiveService(),
      searchPanelService: searchPanelService,
      leaveService: new LeaveService(),
      KeyResultService: new KeyResultService(),
      router: router,
      targetNameArr: new Array<any>(),
      searchTargetArr: new Array<any>(),
      searchLevelArr: new Array<any>(),
      searchOrganizationArr: new Array<any>(),
      searchMoreArr: new Array<any>(),
      targetList: new Array<any>(),
      dutyArr: new Array<any>(),
      searchParams: {
        limit: 10,
        offset: 1,
        dutyType: "2",
        levelId: "", //级别
        orgIds: new Array<any>(), //所属组织,string 集合
        cycleId: "",
        name: "", //关键字
        dutyUsers: new Array<any>(), //负责人,string 集合
        creatorIds: new Array<any>(), //创建人,string 集合
        createTimeStart: "",
        createTimeEnd: "",
        endTimeStart: "",
        endTimeEnd: "",
        status: "", //状态（单选）不传默认全部，0未开始，1进行中，2已结束
        categoryId: "", //围度
      },
      islist: true,
      showNoDate: false,
      targetObj: Object.create(null),
      searchTargetObj: Object.create(null),
      searchLevelObj: Object.create(null),
      searchOrganizationObj: Object.create(null),
      searchMoreObj: Object.create(null),
      selectUserArr: new Array<any>(),
      pageNumber: 0,
      conditionId: "", //选中条件的Id
      condition: {
        id: "",
        searchName: "", //搜索名称
        isDefault: false, //是否默认
        isPushHome: false, //是否推送到首页
        name: "", //任务名称
        createTimeStart: "", //创建时间
        createTimeEnd: "",
        finishTimeStart: "", //完成时间
        finishTimeEnd: "",
        priority: "", //优先级（多选）不传默认全部
        isFinish: "", //是否完成 不传默认全部，0未完成，1已完成
        systemId: "", //来源（多选）不传默认全部
        workUsers: "", //执行人
        joinUsers: "", //所属组织
        creatorId: "", //创建人
        queryType: "okr",
      },
      searchListParams: {
        queryName: "",
        qIsIndexCustomize: "",
        queryType: "okr",
      },
      userInfo: user,
      isShowLeader: false,
      dimensionalityArr: new Array<any>(),
      selSeg: 0,
      targetIndex: 0,
      myResultsList: new Array<any>(),
      $refs: {
        OkrOrgTree: OkrOrgTree,
      },
    };
  },
  mounted() {
    this.userInfo = SystemUtils.loginUser;
    this.onInit();
    this.resetSearchParam();
    (this.selSeg = 0), (this.targetIndex = 0), (this.dimensionalityArr = []);
  },
  methods: {
    //页面初始化
    onInit() {
      // this.getLeader();
      this.getTargetNameArr();
      // this.getLeaveList();
      this.getCateGoryList();
      this.getQueryList(this.searchListParams);
      this.pageNumber = 0;

      // setTimeout(() => {
      //   this.resetPage();
      // }, 500);
    },
    //重新从第一页获取
    resetPage() {
      this.searchParams.offset = 1;
      this.KeyResultService.myResultsList().then((res: any) => {
        this.myResultsList = res.data;
      });
      this.getListRequest((res: any) => {
        this.targetList = res;
        this.showNoDate = res == null || res.length <= 0;
      });
    },
    //刷新
    doRefresh(event: any) {
      this.resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },
    //下一页
    nextPage(event: any) {
      this.searchParams.offset++;
      this.getListRequest((result: any) => {
        if (result) {
          this.targetList = this.targetList.concat(result);
        }
        event.target.complete();
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    //请求数据
    getListRequest(callback: any) {
      this.objectiveService
        .objectiveList(this.searchParams)
        .then((res: any) => {
          this.islist = false;
          callback(res);
        })
        .catch(() => {
          this.islist = false;
        });
    },
    //可用周期列表
    getTargetNameArr() {
      this.objectiveService
        .cycleList()
        .then((res: any) => {
          this.targetNameArr = res;
          let nowDate = moment(new Date()).format("yyyy-MM-DD");
          for (const targetItem of this.targetNameArr) {
            if (
              targetItem.startDate < nowDate &&
              targetItem.endDate > nowDate
            ) {
              this.targetObj = targetItem;
            }
          }
          this.searchParams.cycleId = this.targetObj.id;
          this.resetPage();
        })
        .catch(() => {});
    },
    //是否展示上级
    // getLeader() {
    //   this.searchTargetArr = [
    //     { id: null, name: "全部目标" },
    //     { id: "2", name: "我的目标" },
    //   ];
    //   let params = {
    //     userId: this.userInfo.pernr,
    //   };
    //   this.relationService.getLeaderIds(params).then((result: any) => {
    //     if (result.length > 0) {
    //       this.searchTargetArr.push({
    //         id: "1",
    //         name: "上级指令",
    //       });
    //       this.searchTargetObj = this.searchTargetArr[2];
    //     } else {
    //       this.searchTargetObj = this.searchTargetArr[1];
    //     }
    //     this.searchParams.dutyType = this.searchTargetObj.id;
    //   });
    // },

    //请求查询条件列表
    getQueryList(params: any) {
      this.searchPanelService.getAppQueryByUserId(params).then(
        (res: any) => {
          if (res) {
            this.searchMoreArr = res;
            const all = { id: "queryAll", queryName: "自定义查询" };
            this.searchMoreArr.splice(0, 0, all);
            this.searchMoreObj = this.searchMoreArr[0];
          }
        },
        (e: any) => {}
      );
    },
    //请求级别列表
    // getLeaveList() {
    //   this.leaveService.getLevelList().then(
    //     (res: any) => {
    //       if (res) {
    //         this.searchLevelArr = res.data;
    //         const all = { id: "", name: "级别" };
    //         this.searchLevelArr.splice(0, 0, all);
    //         this.searchLevelObj = this.searchLevelArr[0];
    //       }
    //     },
    //     (e: any) => {}
    //   );
    // },
    subStringText(name: string) {
      if (!name) {
        return "";
      }
      if (name.length > 5) {
        return Utils.subStringText(name, 5);
      } else {
        return name;
      }
    },
    //路由返回
    bindLeftOperate() {
      this.router.go(-1);
    },
    //选择目标下拉框
    async openTargetPopover(ev: any) {
      const popover = await popoverController.create({
        component: TargetNamePopover,
        cssClass: "myPullDown",
        event: ev,
        mode: "md",
        componentProps: {
          targetNameArr: this.targetNameArr,
          targetObj: this.targetObj,
        },
      });
      await popover.present();
      const { data } = await popover.onDidDismiss();
      if (data && data.targetObj) {
        this.targetObj = data.targetObj;
        this.searchParams.cycleId = this.targetObj.id;
        this.resetPage();
        if (this.targetIndex == 3) {
          let okrTree: any = this.$refs.OkrOrgTree;
          if (okrTree)
            okrTree.setDatachange(this.targetObj);
        }
      }
    },
    //下拉查询框
    async openSearchSelece(
      ev: any,
      queryArr: any,
      queryObj: any,
      type: string
    ) {
      const popover = await popoverController.create({
        component: SearchConditionPopover,
        cssClass: "myPullDown",
        event: ev,
        mode: "md",
        componentProps: {
          queryNameArr: queryArr,
          queryObj: queryObj,
          type: type,
        },
      });
      await popover.present();

      const { data } = await popover.onDidDismiss();

      if (data && data.queryObj) {
        this.selSeg = 0;
        setTimeout(() => {
          this.targetIndex = 2;
        });
        this.searchParams.dutyUsers = [];
        this.searchParams.categoryId = "";
        this.dutyArr = [];
        switch (type) {
          case "Targe":
            this.searchTargetObj = data.queryObj;
            this.searchParams.dutyType = this.searchTargetObj.id;
            break;
          case "Level":
            this.searchLevelObj = data.queryObj;
            this.searchParams.levelId = this.searchLevelObj.id;
            break;
          case "More":
            this.searchMoreObj = data.queryObj;
            this.initSeachFormData(data.queryObj);
            break;
        }

        this.resetPage();
      }
    },
    //渲染查询条件数据
    initSeachFormData(data: any) {
      if (data.id == "queryAll") {
        this.searchParams.name = ""; //关键字
        this.searchParams.dutyUsers = []; //负责人,string 集合
        this.searchParams.creatorIds = []; //创建人,string 集合
        this.searchParams.orgIds = []; //所属组织,string 集合
        this.searchParams.createTimeStart = "";
        this.searchParams.createTimeEnd = "";
        this.searchParams.endTimeStart = "";
        this.searchParams.endTimeEnd = "";
        this.searchParams.levelId = ""; //级别
        this.searchParams.status = ""; //状态（单选）不传默认全部，0未开始，1进行中，2已结束
      } else {
        this.searchParams.name = data.qname; //关键字
        this.searchParams.dutyUsers =
          data.qexecutorIds == "" ? [] : data.qexecutorIds.split(","); //负责人,string 集合
        this.searchParams.creatorIds =
          data.qcreatorIds == "" ? [] : data.qcreatorIds.split(","); //创建人,string 集合
        this.searchParams.orgIds =
          data.qparterIds == "" ? [] : data.qparterIds.split(","); //所属组织,string 集合
        this.searchParams.createTimeStart = data.qcreateTimeb;
        this.searchParams.createTimeEnd = data.qcreateTimee;
        this.searchParams.endTimeStart = data.qcompleteTimeb;
        this.searchParams.endTimeEnd = data.qcompleteTimee;
        this.searchParams.levelId = data.qpriorityValues; //级别
        this.searchParams.status =
          typeof data.qisFinished == "number" ? data.qisFinished + "" : ""; //状态（单选）不传默认全部，0未开始，1进行中，2已结束
      }
    },
    async addOKRHandler() {
      this.router.push({
        name: "addOKR",
      });
    },
    //打开组织
    async openOrganizationSelece() {
      let objParam: any = [];
      this.searchParams.orgIds.forEach((ele: any) => {
        objParam.push({ treeId: ele });
      });
      const modal = await modalController.create({
        component: SelectOrganization,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "other",
            queryOrgIds: objParam,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.selectedIds) {
        this.searchParams.orgIds = data.selectedIds;

        this.resetPage();
      }
    },
    //tab切换组件
    bindPageNumber(number: number) {
      this.pageNumber = number;
      this.resetPage();
    },
    //全部目标
    allTarget() {
      this.targetIndex = 0;
      this.selSeg = 0;
      this.searchParams.dutyUsers = [];
      this.dutyArr = [];
      this.searchMoreObj = this.searchMoreArr[0];
      this.initSeachFormData(this.searchMoreObj);
      this.resetPage();
    },
    //目标关系
    targetRelation() {
      this.targetIndex = 3;
    },
    //他人目标
    async otherTarget() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "executor",
            executorList: this.dutyArr,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.data && data.data.length > 0) {
        this.selSeg = 0;
        setTimeout(() => {
          this.targetIndex = 1;
        });
        this.searchParams.categoryId = "";
        this.searchParams.dutyUsers = [];
        this.dutyArr = data.data;
        this.searchMoreObj = this.searchMoreArr[0];
        this.initSeachFormData(this.searchMoreObj);
        this.dutyArr.forEach((dutyItem) => {
          this.searchParams.dutyUsers.push(dutyItem.userId);
        });
        this.resetPage();
      }
    },
    //获取可用维度列表
    getCateGoryList() {
      this.objectiveService.categoryList().then(
        (r: any) => {
          if (r) {
            this.dimensionalityArr = r;
            const all = { id: "", name: "全部" };
            this.dimensionalityArr.splice(0, 0, all);
          }
        },
        (e: any) => {}
      );
    },
    //segmentChanged
    segmentChanged(ev: any) {
      //this.targetIndex = 0;
      this.selSeg = ev.detail.value;
      //this.searchParams.dutyUsers = [];
      this.searchParams.categoryId = this.dimensionalityArr[this.selSeg].id;
      //this.searchMoreObj = this.searchMoreArr[0];
      //this.initSeachFormData(this.searchMoreObj);
      this.resetPage();
    },
    resetSearchParam() {
      this.searchParams = {
        limit: 10,
        offset: 1,
        dutyType: "2",
        levelId: "",
        orgIds: [],
        cycleId: "",
        name: "", //关键字
        dutyUsers: [], //负责人,string 集合
        creatorIds: [], //创建人,string 集合
        createTimeStart: "",
        createTimeEnd: "",
        endTimeStart: "",
        endTimeEnd: "",
        status: "", //状态（单选）不传默认全部，0未开始，1进行中，2已结束
        categoryId: "", //围度
      };
    },

    bindRigthOperate() {
      menuController.open();
    },

    //子组件初始化完成后调用并绑定列表
    onInitPanel(data: Array<any>) {
      //todo
    },

    menuEvent(data: any) {
      this.condition = data;
      this.searchParams.name = data.name; //关键字
      this.searchParams.dutyUsers =
        data.workUsers == "" ? [] : data.workUsers.split(","); //负责人,string 集合
      this.searchParams.creatorIds =
        data.creatorId == "" ? [] : data.creatorId.split(","); //创建人,string 集合
      this.searchParams.orgIds =
        data.joinUsers == "" ? [] : data.joinUsers.split(","); //所属组织,string 集合
      this.searchParams.createTimeStart = data.createTimeStart;
      this.searchParams.createTimeEnd = data.createTimeEnd;
      this.searchParams.endTimeStart = data.finishTimeStart;
      this.searchParams.endTimeEnd = data.finishTimeEnd;
      this.searchParams.levelId = data.priority; //级别
      this.searchParams.status = data.isFinish; //状态（单选）不传默认全部，0未开始，1进行中，2已结束
      //刷新列表 todo
      this.searchMoreObj = this.searchMoreArr[0];
      this.targetIndex = 2;
      this.resetPage();
    },

    openTargetInfo(id: any) {
      this.router.push({
        path: "/OKR/details",
        query: {
          id: id,
        },
      });
    },
    toTheTarget(kr: any) {
      this.router.push({
        name: "addOKR",
        params: {
          sourceKrId: kr.id as string,
          sourceKrName: kr.name as string,
          userId: kr.userId as string,
          userName: kr.userName as string,
          oid: kr.oid as string,
        },
      });
    },
    openOrgTree() {
      this.router.push({
        name: "OkrOrgTree",
        params: {
          targetNameArr: JSON.stringify(this.targetNameArr),
          targetObj: JSON.stringify(this.targetObj),
        },
      });
    },
  },
});
