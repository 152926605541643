import { LeaveApi } from '@/api/okr/LeaveApi';
import BaseService from '../base/BaseService';
 
/**
 * 级别
 */
export class LeaveService extends BaseService {
    
    private api = new LeaveApi(); 
    
    
    //目标级别列表
    public getLevelList(): any {
        return new Promise((resolve, reject) => {
            this.api.getLevelList().then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    
}
