
import { IonContent, popoverController } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "OKRSearchPanelPullDown",
  components: { IonContent },
  props: {
    searchNameArr: Array,
    searchId: String,
    fromType: String
  },
  data() {
    return {
      searchSelectId: this.searchId,
      screenHeight:document.body.clientHeight * 0.9,
    };
  },
  methods: {
    getHeight(){
      let point=0.8;
      if(this.fromType && this.fromType=="taskList"){
        point=0.9;
      }
      return document.body.clientHeight * (document.body.clientHeight/812*point) +'px';
    },
    clickItem(obj: any) {
      if (obj) {
        popoverController.dismiss({
          searchName: obj.queryName,
          selectObj: obj,
          searchID: obj.id,
        });
        this.searchSelectId = obj.id;
      } else popoverController.dismiss();
    },
    clickEmptyItem(){
      popoverController.dismiss();
    }
  },
});
