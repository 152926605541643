
import { menuController, modalController, popoverController } from "@ionic/vue";
import { defineComponent } from "vue";
//时间格式化
import moment from "moment";
//组件
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import SelectOrganization from "@/views/OKR/modals/SelectOrganization.vue";
import SearchConditionManageModal from "./modals/SearchConditionManageModal.vue";
import OKRSearchPanelPullDown from "./OKRSearchPanelPullDown.vue";
import AvatarList from "@/components/AvatarList.vue";
import Utils from "@/utils/Utils";
//数据来源接口
import { SearchPanelService } from "@/services/searchPanel/SearchPanelService";
import { LeaveService } from "@/services/okr/LeaveService";

import BaseService from "@/services/base/BaseService";
import { ObjectiveService } from "@/services/okr/ObjectiveService";

export default defineComponent({
  name: "OKRSearchPanel",
  components: {
    AvatarList,
  },
  props: {
    selectCondition: Object,
    conditionId: String,
  },
  emits: ["on-close", "on-init"],
  data() {
    const searchPanelService = new SearchPanelService();
    return {
      searchPanelService: searchPanelService,
      newSName: "",
      isCondition: false,
      isPull: false,
      searchNameArr: new Array(0),
      newSearchModel: {
        searchName: "",
        isDefault: false,
        isPushHome: false,
      },
      priorityArr: new Array(),
      isFinishArr: [
        { name: "全部", value: "" },
        { name: "未开始", value: "0" },
        { name: "进行中", value: "1" },
        { name: "已结束", value: "2" },
      ],

      systemIdArr: new Array(0),
      ExecutorArr: [], //执行人
      creatArr: [], //创建人
      joinArr: [], //所属组织
      qexecutorNames: "",
      qcreatorNames: "",
      qparterNames: "",
      //----筛选条件字段--------------
      searchPanelForm: {
        id: "",
        searchName: "", //搜索名称
        isDefault: false, //是否默认
        isPushHome: false, //是否推送到首页
        name: "", //任务名称
        createTimeStart: "", //创建时间
        createTimeEnd: "",
        finishTimeStart: "", //完成时间
        finishTimeEnd: "",
        priority: "", //优先级（多选）不传默认全部
        isFinish: "", //是否完成 不传默认全部，0未完成，1已完成
        systemId: "", //来源（多选）不传默认全部
        workUsers: "", //执行人
        creatorId: "", //创建人
        joinUsers: "", //所属组织
        queryType: "okr",
      },
      //----查询条件下拉--------------
      searchListParams: {
        queryName: "",
        qIsIndexCustomize: "",
        queryType: "okr",
      },
      baseService: new BaseService(),
      leaveService: new LeaveService(),
      objectiveService: new ObjectiveService(),
    };
  },
  created() {
    this.initTaskData();
  },
  watch: {
    selectCondition: function () {
      this.initTaskData();
    },
    conditionId: function () {
      this.initTaskData();
    },
  },
  methods: {
    initTaskData() {
      //接收参数
      this.gettaskList();
      this.getLeaveList();
      //请求查询条件
      this.getQueryList(this.searchListParams);
    },
    subStringUserName(name: string) {
      return Utils.subStringUserName(name, 2);
    },
    keyNameHandler(ev: any) {
      const keyCode = window.event ? ev.keyCode : ev.which;
      if (keyCode === 13) {
        this.confirm();
      }
    },
    gettaskList() {
      if (this.conditionId) {
        this.searchPanelForm.id = this.conditionId;
      } else {
        this.searchPanelForm = JSON.parse(JSON.stringify(this.selectCondition));
      }
    },
    //请求级别列表
    getLeaveList() {
      const params = { parentId: "" };
      this.objectiveService.levelList(params).then(
        (res: any) => {
          if (res) {
            this.priorityArr = new Array();
            const all = { id: "", name: "全部" };
            this.priorityArr.push(all);
            this.priorityArr = this.priorityArr.concat(res);
          }
        },
        (e: any) => {}
      );
    },

    //关闭
    onClose() {
      menuController.close();
    },
    //优先级
    getPriority(value: string) {
      this.searchPanelForm.priority = value;
    },
    //是否完成
    getFinish(value: any) {
      this.searchPanelForm.isFinish = value;
    },
    //来源
    getsystemId(value: any) {
      this.searchPanelForm.systemId = value;
    },
    //执行人
    async selectExecutor() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "executor",
            executorList: this.ExecutorArr,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.ExecutorArr = data.data;
      }
    },
    //创建人
    async selectCreator() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "other",
            executorList: this.creatArr,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.creatArr = data.data;
      }
    },
    //所属组织
    async selectOrg() {
      let objParam: any = [];
      if (this.joinArr.length > 0) {
        this.joinArr.forEach((obj: any) => {
          let getQueryObj = {
            checkedId: obj.id,
            checkedName: obj.name,
            checkedType: obj.type,
          };
          objParam.push(getQueryObj);
        });
      }

      const modal = await modalController.create({
        component: SelectOrganization,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          type: "",
          data: {
            queryOrgIds: objParam,
          },
          mulSelect: true,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        let objs: any = [];
        data.data.forEach((ele: any) => {
          objs.push({
            id: ele.checkedId,
            name: ele.checkedName,
            type: ele.checkedType,
          });
        });
        this.joinArr = objs;
      }
    },
    //删除所选的人
    deleteUser(type: any, item: any, index: number) {
      if (type == "Executor") {
        //执行人
        this.ExecutorArr.splice(index, 1);
      } else if (type == "Creator") {
        //创建人
        this.creatArr.splice(index, 1);
      } else if (type == "Joiner") {
        //所属组织
        this.joinArr.splice(index, 1);
      }
    },
    //重置时间
    resetTime(type: any) {
      if (type == "createTime") {
        this.searchPanelForm.createTimeStart = "";
        this.searchPanelForm.createTimeEnd = "";
      } else if (type == "finishTime") {
        this.searchPanelForm.finishTimeStart = "";
        this.searchPanelForm.finishTimeEnd = "";
      }
    },
    //时间处理-创建人处理
    timeFromat() {
      if (this.searchPanelForm.createTimeStart) {
        this.searchPanelForm.createTimeStart = moment(
          this.searchPanelForm.createTimeStart
        ).format("yyyy-MM-DD HH:mm:ss");
      }
      if (this.searchPanelForm.createTimeEnd != "") {
        this.searchPanelForm.createTimeEnd = moment(
          this.searchPanelForm.createTimeEnd
        ).format("yyyy-MM-DD HH:mm:ss");
      }
      if (this.searchPanelForm.finishTimeStart != "") {
        this.searchPanelForm.finishTimeStart = moment(
          this.searchPanelForm.finishTimeStart
        ).format("yyyy-MM-DD HH:mm:ss");
      }
      if (this.searchPanelForm.finishTimeEnd != "") {
        this.searchPanelForm.finishTimeEnd = moment(
          this.searchPanelForm.finishTimeEnd
        ).format("yyyy-MM-DD HH:mm:ss");
      }
      this.searchPanelForm.workUsers = "";
      //执行人
      if (this.ExecutorArr && this.ExecutorArr.length > 0) {
        const cUserArr = this.ExecutorArr;
        let userIds = "";
        let userObjectArr = new Array(0);
        for (let i = 0; i < cUserArr.length; i++) {
          const uId = cUserArr[i]["id"];
          const uName = cUserArr[i]["name"];
          const uPernr = cUserArr[i]["pernr"];
          if (i == 0) {
            userIds += cUserArr[i]["id"];
          } else {
            userIds += "," + cUserArr[i]["id"];
          }
          userObjectArr.push({ id: uId, name: uName, pernr: uPernr });
        }
        if (userIds) {
          this.searchPanelForm.workUsers = userIds;
          this.qexecutorNames = JSON.stringify(userObjectArr);
        }
      }

      this.searchPanelForm.creatorId = "";
      //创建人
      if (this.creatArr && this.creatArr.length > 0) {
        const cUserArr = this.creatArr;
        let userIds = "";
        let userObjectArr = new Array(0);
        for (let i = 0; i < cUserArr.length; i++) {
          const uId = cUserArr[i]["id"];
          const uName = cUserArr[i]["name"];
          const uPernr = cUserArr[i]["pernr"];
          if (i == 0) {
            userIds += cUserArr[i]["id"];
          } else {
            userIds += "," + cUserArr[i]["id"];
          }
          userObjectArr.push({ id: uId, name: uName, pernr: uPernr });
        }
        if (userIds) {
          this.searchPanelForm.creatorId = userIds;
          this.qcreatorNames = JSON.stringify(userObjectArr);
        }
      }

      //所属组织
      this.searchPanelForm.joinUsers = "";
      if (this.joinArr && this.joinArr.length > 0) {
        const cOrgArr = this.joinArr;
        let orgIds = "";
        let orgObjectArr = new Array(0);
        for (let i = 0; i < cOrgArr.length; i++) {
          const uId = cOrgArr[i]["id"];
          const uName = cOrgArr[i]["name"];
          const uType = cOrgArr[i]["type"];
          if (i == 0) {
            orgIds += cOrgArr[i]["id"];
          } else {
            orgIds += "," + cOrgArr[i]["id"];
          }
          orgObjectArr.push({ id: uId, name: uName, type: uType });
        }
        if (orgIds) {
          this.searchPanelForm.joinUsers = orgIds;
          this.qparterNames = JSON.stringify(orgObjectArr);
        }
      } else {
        this.searchPanelForm.joinUsers = "";
        this.qparterNames = "";
      }
    },
    //确定检索
    confirm() {
      this.timeFromat();
      let params = {
        limit: 10,
        offset: 1,
        name: this.searchPanelForm.name, //任务名称
        createTimeStart: this.searchPanelForm.createTimeStart, //创建时间
        createTimeEnd: this.searchPanelForm.createTimeEnd,
        finishTimeStart: this.searchPanelForm.finishTimeStart, //完成时间
        finishTimeEnd: this.searchPanelForm.finishTimeEnd,
        priority: this.searchPanelForm.priority, //优先级（多选）不传默认全部
        isFinish: this.searchPanelForm.isFinish, //是否完成 不传默认全部，0未完成，1已完成
        systemId: this.searchPanelForm.systemId, //来源（多选）不传默认全部
        workUsers: this.searchPanelForm.workUsers,
        creatorId: this.searchPanelForm.creatorId,
        joinUsers: this.searchPanelForm.joinUsers,
      };
      if (Utils.isInCludeEmoji(params.name)) {
        Utils.presentToastWarning("关键字不可以包含表情");
        return;
      }
      params.name = this.baseService.trim(params.name);
      this.$emit("on-close", params);
      this.onClose();
    },
    //重置检索条件
    reset() {
      this.searchPanelForm = {
        id: "",
        searchName: "", //搜索名称
        isDefault: false, //是否默认
        isPushHome: false, //是否推送到首页
        name: "", //任务名称
        createTimeStart: "", //创建时间
        createTimeEnd: "",
        finishTimeStart: "", //完成时间
        finishTimeEnd: "",
        priority: "", //优先级（多选）不传默认全部
        isFinish: "", //是否完成 不传默认全部，0未完成，1已完成
        systemId: "", //来源（多选）不传默认全部
        workUsers: "",
        creatorId: "",
        joinUsers: "",
        queryType: "okr",
      };
      this.ExecutorArr = []; //执行人
      this.creatArr = []; //创建人
      this.joinArr = []; //所属组织
    },

    //打开条件名称框
    openCondition() {
      this.resetNewModel();
      this.isCondition = true;
    },
    //关闭条件名称框
    CloseDialog() {
      this.isCondition = false;
    },
    //请求查询条件列表
    getQueryList(params: any) {
      this.searchPanelService.getAppQueryByUserId(params).then(
        (res: any) => {
          if (res) {
            this.searchNameArr = res;
            this.$emit("on-init", res);
          }
        },
        (e: any) => {
          this.$emit("on-init", new Array(0));
        }
      );
    },
    //下拉框查询条件
    async pullShow(ev: any) {
      const popover = await popoverController.create({
        component: OKRSearchPanelPullDown,
        cssClass: "myPullDown",
        event: ev,
        componentProps: {
          searchNameArr: this.searchNameArr,
          searchName: this.searchPanelForm.searchName,
          searchId: this.searchPanelForm.id,
        },
      });
      await popover.present();
      const { data } = await popover.onDidDismiss();
      if (data && data.searchName != "") {
        this.searchPanelForm.searchName = data.searchName;
        //this.searchName = data.searchName;
        this.searchPanelForm.id = data.searchId;
        this.initSeachFormData(data.selectObj);
      }
    },
    //渲染查询条件数据
    initSeachFormData(params: any) {
      this.searchPanelForm = {
        id: params.id,
        searchName: params.queryName, //搜索名称
        isDefault: params.qisDefault, //是否默认
        isPushHome: params.qisIndexCustomize, //是否推送到首页
        name: params.qname, //任务名称
        createTimeStart: params.qcreateTimeb, //创建时间
        createTimeEnd: params.qcreateTimee,
        finishTimeStart: params.qcompleteTimeb, //完成时间
        finishTimeEnd: params.qcompleteTimee,
        priority: params.qpriorityValues, //优先级（多选）不传默认全部
        isFinish:
          typeof params.qisFinished == "number" ? params.qisFinished + "" : "", //是否完成 不传默认全部，0未完成，1已完成
        systemId: params.qsource, //来源（多选）不传默认全部
        workUsers: params.qexecutorIds,
        creatorId: params.qcreatorIds,
        joinUsers: params.qparterIds,
        queryType: params.queryType,
      };
      this.ExecutorArr = [];
      this.creatArr = [];
      this.joinArr = [];
      if (params.qexecutorIds && params.qexecutorNames) {
        this.ExecutorArr = eval("(" + params.qexecutorNames + ")");
      }

      if (params.qcreatorIds && params.qcreatorNames) {
        this.creatArr = eval("(" + params.qcreatorNames + ")");
      }

      if (params.qparterIds && params.qparterNames) {
        this.joinArr = eval("(" + params.qparterNames + ")");
      }
    },
    //保存查询条件
    saveCondition(type: number) {
      if (type == 1) {
        if (
          !this.newSearchModel.searchName ||
          this.newSearchModel.searchName.replace(/\s+/g, "") == ""
        ) {
          Utils.presentToastWarning("请填写查询条件名称");
          return;
        }
        this.timeFromat();
        let params = {
          queryName: this.newSearchModel.searchName,
          queryType: this.searchPanelForm.queryType,
          qisDefault: this.newSearchModel.isDefault,
          qisIndexCustomize: this.newSearchModel.isPushHome,
          qname: this.searchPanelForm.name,
          qexecutorIds: this.searchPanelForm.workUsers,
          qexecutorNames: this.qexecutorNames,
          qcreatorNames: this.qcreatorNames,
          qcreatorIds: this.searchPanelForm.creatorId,
          qcreateTimeb: this.searchPanelForm.createTimeStart,
          qcreateTimee: this.searchPanelForm.createTimeEnd,
          qcompleteTimeb: this.searchPanelForm.finishTimeStart,
          qcompleteTimee: this.searchPanelForm.finishTimeEnd,
          qpriorityValues: this.searchPanelForm.priority,
          qisFinished: this.searchPanelForm.isFinish,
          qsource: this.searchPanelForm.systemId,
          qparterIds: this.searchPanelForm.joinUsers,
          qparterNames: this.qparterNames,
          id: "",
        };
        this.searchPanelService.saveSearch(params).then(
          (res: any) => {
            if (res) {
              this.getQueryList(this.searchListParams);
              this.searchPanelForm.id = res.id;
              this.searchPanelForm.searchName = this.newSearchModel.searchName;
              Utils.presentToastSuccess("保存成功");
            } else {
              Utils.presentToastWarning("查询条件名称不允许重复");
            }
          },
          (e: any) => {
            Utils.presentToastWarning("查询条件名称不允许重复");
          }
        );
        this.isCondition = false;

        return;
      } else {
        this.isCondition = false;
      }
    },
    //条件管理
    async openSearchConditionManageModal() {
      const modal = await modalController.create({
        component: SearchConditionManageModal,
        cssClass: "notice-modal-class",
        backdropDismiss: true,
        componentProps: {
          systemIdArr: this.systemIdArr,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data.queryList) {
        const selectArr: Array<any> = data.queryList.filter((item: any) => {
          return item.id == this.searchPanelForm.id;
        });
        if (selectArr.length > 0) {
          this.newSearchModel.searchName = selectArr[0].queryName;
          this.initSeachFormData(selectArr[0]);
        } else {
          //已删除 清空查询条件
          this.searchNameArr = data.queryList;
          this.$emit("on-init", this.searchNameArr);
          this.reset();
        }
      }
    },
    resetNewModel() {
      this.newSearchModel.searchName = "";
      this.newSearchModel.isDefault = false;
      this.newSearchModel.isPushHome = false;
    },
    //设为默认
    changeDefault(e: any) {
      this.newSearchModel.isDefault = e.detail.checked;
    },
    //推送到首页
    changePushHome(e: any) {
      this.newSearchModel.isPushHome = e.detail.checked;
    },
    avatarData(item: any) {
      var users: any = [];
      let obj = { name: item.name, img: item.photo, id: item.userId };
      users.push(obj);
      return users;
    },
  },
});
