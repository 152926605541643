
import { popoverController } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SearchConditionPopover",
  components: { },
  props: {
    queryNameArr: Array,
    queryObj:{
        type:Object,
        default:{
            id:"",
            name:""
        }
    }, 
    type:String
  },
  data() {
    return {
      searchSelectId:this.queryObj.id,
      screenHeight:document.body.clientHeight * 0.9,
    };
  },
  methods: {
    getHeight(){
      let point=0.8; 
      return document.body.clientHeight * (document.body.clientHeight/812*point) +'px';
    },
    clickItem(obj: any) {
      if (obj) { 
        this.searchSelectId=obj.id 
        popoverController.dismiss({
          queryNameArr: this.type!='More'?obj.name:obj.queryName,
          queryObj: obj, 
        });
      } else popoverController.dismiss();
    },
  },
});
