
import { menuController, modalController, alertController } from "@ionic/vue";
import { defineComponent } from "vue";
//时间格式化
import moment from "moment";
//组件
import Utils from "@/utils/Utils";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import SelectOrganization from "@/views/OKR/modals/SelectOrganization.vue";
import AvatarList from "@/components/AvatarList.vue";

import { SearchPanelService } from "@/services/searchPanel/SearchPanelService";
import { LeaveService } from "@/services/okr/LeaveService";
export default defineComponent({
  name: "EditSearchCondition",
  components: {
    HeaderModal,
    AvatarList,
  },
  props: {
    queryObj: Object,
    systemIdArr: Array,
  },
  data() {
    const searchPanelService = new SearchPanelService();
    return {
      searchPanelService: searchPanelService,
      newSName: "",
      isCondition: false,
      isPull: false,
      queryNameArr: new Array(0),
      priorityArr: new Array(),
      isFinishArr: [
        { name: "全部", value: "" },
        { name: "未开始", value: "0" },
        { name: "进行中", value: "1" },
        { name: "已结束", value: "2" },
      ],
      queryName: "",
      ExecutorArr: [], //执行人
      creatArr: [], //创建人
      joinArr: [], //所属组织
      qexecutorNames: "",
      qcreatorNames: "",
      qparterNames: "",
      //----筛选条件字段--------------
      searchPanelForm: {
        id: "",
        queryName: "", //搜索名称
        qisDefault: false, //是否默认
        qisIndexCustomize: false, //是否推送到首页
        qname: "", //任务名称
        qcreateTimeb: "", //创建时间
        qcreateTimee: "",
        qcompleteTimeb: "", //完成时间
        qcompleteTimee: "",
        qpriorityValues: "", //优先级（多选）不传默认全部
        qisFinished: "", //是否完成 不传默认全部，0未完成，1已完成
        qexecutorIds: "", //执行人
        qcreatorIds: "", //创建人
        qparterIds: "", //所属组织
        queryType: "okr",
      },
      //----查询条件下拉--------------
      searchListParams: {
        queryName: "",
        qIsIndexCustomize: "",
        queryType: "okr",
      },
      leaveService: new LeaveService(),
    };
  },
  mounted() {
    this.initTaskData();
  },
  methods: {
    initTaskData() {
      let that=this;
      this.getLeaveList(function(){
         that.initSeachFormData(that.queryObj,that);
      });
    },
    //渲染查询条件数据
    initSeachFormData(params: any,that:any) {
      that.searchPanelForm = {
        id: params.id,
        queryName: params.queryName, //搜索名称
        qisDefault: params.qisDefault, //是否默认
        qisIndexCustomize: params.qisIndexCustomize, //是否推送到首页
        qname: params.qname, //任务名称
        qcreateTimeb: params.qcreateTimeb, //创建时间
        qcreateTimee: params.qcreateTimee,
        qcompleteTimeb: params.qcompleteTimeb, //完成时间
        qcompleteTimee: params.qcompleteTimee,
        qpriorityValues: params.qpriorityValues, //优先级（多选）不传默认全部
        qisFinished:
          typeof params.qisFinished == "number" ? params.qisFinished + "" : "", //是否完成 不传默认全部，0未完成，1已完成
        qexecutorIds: params.qexecutorIds,
        qcreatorIds: params.qcreatorIds,
        qparterIds: params.qparterIds,
        queryType: params.queryType,
      };
      if (params.qexecutorIds && params.qexecutorNames) {
        that.ExecutorArr = eval("(" + params.qexecutorNames + ")");
      }

      if (params.qparterIds && params.qparterNames) {
        that.joinArr = eval("(" + params.qparterNames + ")");
      }

      if (params.qcreatorIds && params.qcreatorNames) {
        that.creatArr = eval("(" + params.qcreatorNames + ")");
      }
    },
    //关闭
    onClose(type: any) {
      modalController.dismiss();
    },
    //优先级
    getPriority(value: string) {
      this.searchPanelForm.qpriorityValues = value;
    },
    //是否完成
    getFinish(value: any) {
      this.searchPanelForm.qisFinished = value;
    },
     //请求级别列表
    getLeaveList(callback:any) {
      this.leaveService.getLevelList().then(
        (res: any) => {
          if (res) {
            this.priorityArr=new Array();
            const all = { id: "", name: "全部" };
            this.priorityArr.push(all);
            this.priorityArr =this.priorityArr.concat(res.data);
            callback();
          }
        },
        (e: any) => {}
      );
    },
    //执行人
    async selectExecutor() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "executor",
            executorList: this.ExecutorArr,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.ExecutorArr = data.data;
      }
    },
    //创建人
    async selectCreator() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "other",
            executorList: this.creatArr,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.creatArr = data.data;
      }
    },
    //所属组织
    async selectOrg() {
      let objParam:any=[];
      if(this.joinArr.length>0){
        this.joinArr.forEach((obj:any) => {
          let getQueryObj = {
              checkedId: obj.id,
              checkedName: obj.name,
              checkedType: obj.type,
            };
            objParam.push(getQueryObj);
        });
      }
      const modal = await modalController.create({
        component: SelectOrganization,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
           type: "",
            data:{
              queryOrgIds:objParam
            },
            mulSelect:true,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        let objs:any=[];
        data.data.forEach((ele:any) => {
          objs.push({id:ele.checkedId,name:ele.checkedName,type:ele.checkedType});
        });
        this.joinArr = objs;
      }
    },
    //删除所选的人
    deleteUser(type: any, item: any, index: number) {
      if (type == "Executor") {
        //执行人
        this.ExecutorArr.splice(index, 1);
      } else if (type == "Creator") {
        //创建人
        this.creatArr.splice(index, 1);
      } else if (type == "Joiner") {
        //所属组织
        this.joinArr.splice(index, 1);
      }
    },
    //重置时间
    resetTime(type: any) {
      if (type == "createTime") {
        this.searchPanelForm.qcreateTimeb = "";
        this.searchPanelForm.qcreateTimee = "";
      } else if (type == "finishTime") {
        this.searchPanelForm.qcompleteTimeb = "";
        this.searchPanelForm.qcompleteTimee = "";
      }
    },
    //时间处理-创建人处理
    timeFromat() {
      if (this.searchPanelForm.qcreateTimeb) {
        this.searchPanelForm.qcreateTimeb = moment(
          this.searchPanelForm.qcreateTimeb
        ).format("yyyy-MM-DD HH:mm:ss");
      }
      if (this.searchPanelForm.qcreateTimee != "") {
        this.searchPanelForm.qcreateTimee = moment(
          this.searchPanelForm.qcreateTimee
        ).format("yyyy-MM-DD HH:mm:ss");
      }
      if (this.searchPanelForm.qcompleteTimeb != "") {
        this.searchPanelForm.qcompleteTimeb = moment(
          this.searchPanelForm.qcompleteTimeb
        ).format("yyyy-MM-DD HH:mm:ss");
      }
      if (this.searchPanelForm.qcompleteTimee != "") {
        this.searchPanelForm.qcompleteTimee = moment(
          this.searchPanelForm.qcompleteTimee
        ).format("yyyy-MM-DD HH:mm:ss");
      }
      //执行人
      if (this.ExecutorArr && this.ExecutorArr.length > 0) {
        const cUserArr = this.ExecutorArr;
        let userIds = "";
        let userObjectArr = new Array(0);
        for (let i = 0; i < cUserArr.length; i++) {
          const uId = cUserArr[i]["id"];
          const uName = cUserArr[i]["name"];
          const uPernr = cUserArr[i]["pernr"];
          if (i == 0) {
            userIds += cUserArr[i]["id"];
          } else {
            userIds += "," + cUserArr[i]["id"];
          }
          userObjectArr.push({ id: uId, name: uName, pernr: uPernr });
        }
        if (userIds) {
          this.searchPanelForm.qexecutorIds = userIds;
          this.qexecutorNames = JSON.stringify(userObjectArr);
        }
      }
      //创建人
      if (this.creatArr && this.creatArr.length > 0) {
        const cUserArr = this.creatArr;
        let userIds = "";
        let userObjectArr = new Array(0);
        for (let i = 0; i < cUserArr.length; i++) {
          const uId = cUserArr[i]["id"];
          const uName = cUserArr[i]["name"];
          const uPernr = cUserArr[i]["pernr"];
          if (i == 0) {
            userIds += cUserArr[i]["id"];
          } else {
            userIds += "," + cUserArr[i]["id"];
          }
          userObjectArr.push({ id: uId, name: uName, pernr: uPernr });
        }
        if (userIds) {
          this.searchPanelForm.qcreatorIds = userIds;
          this.qcreatorNames = JSON.stringify(userObjectArr);
        }
      }

      //所属组织
      if (this.joinArr && this.joinArr.length > 0) {
        const cOrgArr = this.joinArr;
        let orgIds = "";
        let orgObjectArr = new Array(0);
        for (let i = 0; i < cOrgArr.length; i++) {
          const uId = cOrgArr[i]["id"];
          const uName = cOrgArr[i]["name"];
          const uType = cOrgArr[i]["type"];
          if (i == 0) {
            orgIds += cOrgArr[i]["id"];
          } else {
            orgIds += "," + cOrgArr[i]["id"];
          }
          orgObjectArr.push({ id: uId, name: uName,type:uType });
        }
        if (orgIds) {
          this.searchPanelForm.qparterIds = orgIds;
          this.qparterNames = JSON.stringify(orgObjectArr);
        }
      } else {
        this.searchPanelForm.qparterIds = "";
        this.qparterNames = "";
      }
    },

    //重置检索条件
    reset() {
      this.searchPanelForm = {
        id: "",
        queryName: "", //搜索名称
        qisDefault: false, //是否默认
        qisIndexCustomize: false, //是否推送到首页
        qname: "", //任务名称
        qcreateTimeb: "", //创建时间
        qcreateTimee: "",
        qcompleteTimeb: "", //完成时间
        qcompleteTimee: "",
        qpriorityValues: "", //优先级（多选）不传默认全部
        qisFinished: "", //是否完成 不传默认全部，0未完成，1已完成
        qexecutorIds: "", //执行人
        qcreatorIds: "", //创建人
        qparterIds: "", //所属组织
        queryType: "okr",
      };
      this.ExecutorArr = [];
      this.joinArr = [];
      this.creatArr = [];
    },

    //保存
    confirm(type: number) {
      if (
        !this.searchPanelForm.queryName ||
        this.searchPanelForm.queryName.replace(/\s+/g, "") == ""
      ) {
        Utils.presentToastTopWarning("请填写查询条件名称");
        return false;
      }
      this.timeFromat();

      let newPara = {
        qexecutorNames: this.qexecutorNames,
        qparterNames: this.qparterNames,
        qcreatorNames: this.qcreatorNames,
      };
      this.searchPanelForm = Object.assign(
        this.searchPanelForm,
        JSON.parse(JSON.stringify(newPara))
      );

      this.searchPanelService.saveSearch(this.searchPanelForm).then(
        (res: any) => {
          if (res) {
            modalController.dismiss({
              isSussess: true,
              isDeleted: false,
              newModle: this.searchPanelForm,
            });
          }
        },
        (e: any) => {
          Utils.presentToastTopWarning(e.message);
        }
      );
    },
    //删除
    deleteQueryCondition() {
      if (this.searchPanelForm.qisIndexCustomize) {
        Utils.presentToastTopWarning("请先取消推送到首页定制");
      } else {
        this.delet();
      }
    },
    async delet() {
      const alert = await alertController.create({
        header: "删除",
        message: "您确定要删除此条件吗？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "确定",
            handler: () => {
              //删除
              this.searchPanelService.clearById(this.searchPanelForm.id).then(
                (res: any) => {
                  if (res) {
                    Utils.presentToastTop("删除成功！");
                    modalController.dismiss({
                      isSussess: true,
                      isDeleted: true,
                      newModle: null,
                    });
                  }
                },
                (e: any) => {
                  console.log(e.message);
                }
              );
            },
          },
        ],
      });

      await alert.present();
    },

    //设为默认
    changeDefault(e: any) {
      this.searchPanelForm.qisDefault = e.detail.checked;
    },
    //推送到首页
    changePushHome(e: any) {
      this.searchPanelForm.qisIndexCustomize = e.detail.checked;
    },
    avatarData(item: any) {
      var users: any = [];
      let obj = { name: item.name, img: item.photo, id: item.userId };
      users.push(obj);
      return users;
    },
  },
});
