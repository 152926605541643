/**
 * okr目标级别 api
 */
import OkrBaseService from '../base/OkrBaseService';

export class LeaveApi extends OkrBaseService {

    constructor() {
        super("okr/level");
    }
    //目标级别列表
    public getLevelList(): Promise<any> {
        const path = 'list';
        return this.request(path, this.rest.METHOD_GET);
    }

}